<template>
<el-form @submit.native.prevent ref="form" :model="form" :rules="rules" label-width="120px">
  <el-row style="margin:40px;">
    <el-col :span="12" style="height:55px;">
       <el-form-item label-width="160px" label="当月总金额不能超过" prop="monthTotalamount">
          <el-input style="width:190px;" placeholder="输入整数且小于1000万" v-model.number="form.monthTotalamount" maxlength="8" ></el-input> 元
       </el-form-item>
    </el-col>
    <el-col :span="10" style="height:55px;">
       <el-form-item label="不限制" >
          <el-switch v-model="form.monthTotalAmountIsEnable"></el-switch>
       </el-form-item>
    </el-col>
    <el-col :span="4" style="height:55px;">
       <el-form-item label-width="90px" label="单个手机号" >
          <el-select  size="mini" style="width:120px;margin-right:15px;" v-model="form.phoneTriesLimitTimeQuantum"  >
              <el-option label="一周" :value="0"></el-option>
              <el-option label="一个月" :value="1"></el-option>
              <el-option label="两个月" :value="2"></el-option>
              <el-option label="六个月" :value="3"></el-option>
              <el-option label="一年" :value="4"></el-option>
          </el-select>
       </el-form-item>
    </el-col>
   <el-col :span="8" style="height:55px;">
       <el-form-item label-width="155px" label="发放次数不超过" prop="phoneTriesLimit">
          <el-input style="width:180px;" placeholder="输入整数" v-model.number="form.phoneTriesLimit" maxlength="16" ></el-input> 次
       </el-form-item>
    </el-col>
    <el-col :span="10" style="height:55px;">
       <el-form-item label="不限制" >
          <el-switch v-model="form.phoneTriesLimitIsEnable"></el-switch>
       </el-form-item>
    </el-col>
    <el-col :span="4" style="height:55px;">
       <el-form-item label-width="90px" label="单个手机号" >
          <el-select  size="mini" style="width:120px;margin-right:15px;" v-model="form.phoneAmountTimeQuantum"  >
              <el-option label="一周" :value="0"></el-option>
              <el-option label="一个月" :value="1"></el-option>
              <el-option label="两个月" :value="2"></el-option>
              <el-option label="六个月" :value="3"></el-option>
              <el-option label="一年" :value="4"></el-option>
          </el-select>
       </el-form-item>
    </el-col>
    <el-col :span="8" style="height:55px;">
       <el-form-item  label-width="155px" label="发放金额不超过" prop="phoneAmount" >
          <el-input style="width:180px;" placeholder="输入整数且小于1000万" v-model.number="form.phoneAmount" maxlength="16" ></el-input> 元
       </el-form-item>
    </el-col>
    <el-col :span="8" style="height:55px;">
       <el-form-item label="不限制" >
          <el-switch v-model="form.phoneAmountIsEnable"></el-switch>
       </el-form-item>
    </el-col>
    <el-col :span="16" style="margin-top:30px;">
        <el-button  type="primary" v-debounce="onSubmit" >立即保存</el-button>
    </el-col>
</el-row>
</el-form>
</template>
<script>
import { queryRule, editConfiguring } from '@/api/setting';
 
  export default {
    data() {
      var checkMoney = (rule, value, callback) => {
        let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/;
        if (reg.test(value)) {
          callback()
        }else{
          callback(new Error('请输入正确金额'));
        }

        setTimeout(() => {
          if (!Number.isInteger(value)) {
            callback(new Error('请输入正确金额'));
          } else {
            callback();
          }
        }, 1000);
      };
      var checkNumber = (rule, value, callback)=>{
        let reg = /^[1-9]\d*$/;
        if (reg.test(value)) {
          callback()
        }else{
          callback(new Error('请输入正确天数'));
        }
      };
      return {
        organizationList:[],
        roleList:[],
        form: {
          monthTotalamount:'',
          monthTotalAmountIsEnable:'',

          phoneTriesLimitTimeQuantum:'',
          phoneTriesLimit:'',
          phoneTriesLimitIsEnable:'',
          
          phoneAmountTimeQuantum:'',
          phoneAmount:'',
          phoneAmountIsEnable:'',
          
        },
        rules:{
          monthTotalamount:[
              {required: true, message: '金额不可为空', trigger: 'blur'},
              {validator:checkMoney, trigger:'blur' }
          ],
          phoneTriesLimit:[
              {required: true, message: '次数不可为空', trigger: 'blur'},
              {validator:checkNumber, trigger:'blur' }
          ],
          phoneAmount:[
              {required: true, message: '金额不可为空', trigger: 'blur'},
              {validator:checkMoney, trigger:'blur' }
          ],
          
        }
      }
    },
    computed:{
      
    },
    created(){
      this.getDateil()
    },
    mounted(){
     
    },
    methods: {
      getDateil(){
        let that = this;
        queryRule()
        .then((response) => {
            if(response.code == 200){
                that.roleList = response.data;
                let roleList = response.data;
                that.form = {
                  monthTotalamount: roleList[0].sumMoney,
                  monthTotalAmountIsEnable:roleList[0].isEnable ? false : true,
                  
                  phoneTriesLimitTimeQuantum:Number(roleList[1].timeQuantum),
                  phoneTriesLimit:roleList[1].triesLimit,
                  phoneTriesLimitIsEnable:roleList[1].isEnable ? false : true,

                  phoneAmountTimeQuantum:Number(roleList[2].timeQuantum),
                  phoneAmount:roleList[2].sumMoney,
                  phoneAmountIsEnable:roleList[2].isEnable ? false : true,
                  
                }
            }else{
                that.$message.error({
                    title: '错误',
                    message: response.msg,
                    duration: 1500
                });
            }
            
        })
      },
     onSubmit(formName='form'){
        let that = this;
        let form = JSON.parse(JSON.stringify(that.form));
        
          this.$refs[formName].validate((valid)=>{
            if(valid){
              let rule = that.roleList;
              rule[0].sumMoney = form.monthTotalamount;
              rule[0].isEnable = form.monthTotalAmountIsEnable ? '0':'1';

              rule[1].timeQuantum = form.phoneTriesLimitTimeQuantum;
              rule[1].triesLimit = form.phoneTriesLimit;
              rule[1].isEnable = form.phoneTriesLimitIsEnable ? '0':'1';

              rule[2].timeQuantum = form.phoneAmountTimeQuantum;
              rule[2].sumMoney = form.sumMoney;
              rule[2].isEnable = form.phoneAmountIsEnable ? '0':'1';

              editConfiguring(rule)
              .then((response) => {
                  if(response.code == 200){
                      that.$message({
                          message: response.msg,
                          type: 'success'
                      });
                  }else{
                      that.$message.error({
                          title: '错误',
                          message: response.msg,
                          duration: 1500
                      });
                  }
              })
            }else{
              this.$message.error({
                  title: '错误',
                  message: '请检查完善输入项并重试',
                  duration: 1500
              });
              return false;
            }
          })
      }
    },
    
  }
</script>
<style scoped>
.el-form-item{
  text-align: left;
}
.content-text{
  letter-spacing: 1px;
  color:#333333;
  margin:0px 10px;
  font-size:16px;
}
</style>